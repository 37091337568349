<template>
  <v-form ref="form">

    <v-card-text>
      <v-row>
        <v-col>
          <p class="mt-3 mb-0" style="color: red; font-size: 1.15em">Please note that extensions are temporarily not being cloned, and need to be copied manually to the new campaign. 
            Sorry for the inconvenience.
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="6">
          <div class="mt-2 mb-5">
            <h2 center>Source</h2><small>Select account and campaign</small>
          </div>

          <GoogleAccountAutoComplete 
            v-model="selectedSourceAccountID"
            @changed="sourceAccountChanged"
            @refreshing="accountsRefreshing"
            accountSet="blueprintTest"
          ></GoogleAccountAutoComplete>

          <GoogleCampaignAutoComplete 
            v-model="selectedSourceCampaign"
            :accountID="selectedSourceAccountID"
            @changed="sourceCampaignChanged"
            @refreshing="campaignsRefreshing" 
          ></GoogleCampaignAutoComplete>

        </v-col>

        <v-col md="6">
          <div class="mt-2 mb-5">
            <h2 center>Destination</h2><small>Select account, new campaign name, and geo targets</small>
          </div>

          <GoogleAccountAutoComplete 
            v-model="selectedDestAccountID"
            label="Account (non managers only)"
            @changed="destAccountChanged"
            @refreshing="accountsRefreshing"
            accountSet="nonManager"
          ></GoogleAccountAutoComplete>

          <v-text-field v-model="destCampaignName" label="New campaign name" prepend-icon="campaign" :disabled="!selectedDestAccountID" required :rules="[v => !!v || 'New campaign name is required']">
          </v-text-field>

          <v-autocomplete v-model="selectedDestCampGeoIDs" :items="geos" multiple clearable :loading="loadingGeos" item-text="name" item-value="criterion_id" label="New campaign target geos"
            prepend-icon="public" :disabled="!selectedDestAccountID">
          </v-autocomplete>

        </v-col>

      </v-row>

    </v-card-text>

    <v-card-actions class="pl-5 pb-5">
      <v-btn color="primary" :loading="running" @click="clone()">Clone</v-btn>

      <v-fade-transition mode="out-in">
        <v-btn color="green darken-1" text v-show="showCompleted">Completed</v-btn>
      </v-fade-transition>

    </v-card-actions>
  </v-form>
</template>

<script>
export default {
  name: 'CloningToolCloneCampaign',

  data () {
    return {
      loadingGeos: false,
      cloneCampaignEndpoint: '/a/google/clone-campaign',
      selectedSourceAccountID: 0,
      selectedDestAccountID: 0,
      selectedSourceCampaign: null,
      destCampaignName: null,
      selectedDestCampGeoIDs: [],
      lsSourceAccount: 'cloningTool_selectedSourceAccount',
      lsDestAccount: 'cloningTool_selectedDestAccount',
      running: false,
      showCompleted: false,
      sourceCampaigns: [],
    }
  },

  computed: {
    geos () {
      return this.$store.state.google.countryGeoTargets
    },
  },

  components: {
    GoogleAccountAutoComplete: () => import('@/components/autocompletes/GoogleAccountAutoComplete.vue'),
    GoogleCampaignAutoComplete: () => import('@/components/autocompletes/GoogleCampaignAutoComplete.vue')
  },

  methods: {
    accountsRefreshing () {
      this.selectedSourceAccountID = 0
      this.selectedDestAccountID = 0

      this.selectedSourceCampaign = null
      this.destCampaignName = ''
    },

    campaignsRefreshing () {
      this.selectedSourceCampaign = null
      this.destCampaignName = ''
    },

    clone: function () {
      // make sure all selects have a value
      if (!this.$refs.form.validate()) {
        return
      }
      this.running = true

      // gather params and send to API
      var creationObj = {
        'sourceAccountID': this.selectedSourceAccountID,
        'sourceCampaignID': this.selectedSourceCampaign.campaign_id,
        'destAccountID': this.selectedDestAccountID,
        'destCampaignName': this.destCampaignName,
        'destCampaignGeoIDs': this.selectedDestCampGeoIDs,
      }
      var body = JSON.stringify(creationObj)
      //console.log(body)

      this.$http.post(this.cloneCampaignEndpoint, body).then(resp => {
        this.showCompleted = true
        this.timer = setTimeout(() => { this.showCompleted = false }, 3000)
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.cloneCampaignEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.running = false)
    },

    destAccountChanged: function () {
      localStorage.setItem(this.lsDestAccount, JSON.stringify(this.selectedDestAccountID))
    },

    sourceAccountChanged: function () {
      localStorage.setItem(this.lsSourceAccount, JSON.stringify(this.selectedSourceAccountID))
    },

    sourceCampaignChanged: function () {
      // set suggested dest campaign name
      this.destCampaignName = this.selectedSourceCampaign.name
    },
  },

  created: function () {
    // if present, use local accounts
    if (localStorage.getItem(this.lsSourceAccount)) {
      this.selectedSourceAccountID = JSON.parse(localStorage.getItem(this.lsSourceAccount))
      this.sourceAccountChanged()
    }
    if (localStorage.getItem(this.lsDestAccount)) {
      this.selectedDestAccountID = JSON.parse(localStorage.getItem(this.lsDestAccount))
      this.destAccountChanged()
    }
  },
}
</script>
